import axios from "axios"
import { postFormData } from "../../components/forms/api/Api"
import * as qs from "query-string"

const SiteName = "Arabian Estates"
const AltSiteName = " - Arabian Estates"
const NewsBaseURL = "/about/latest-property-news-and-videos/"
const TeamBaseURL = "/about/our-team/"
const AreaguideBaseURL = "/about/area-guides/"
const OfficeBaseURL = "/contact-arabian-estates/"
const ListPropertyBaseURL = "/list-a-property/"

const AppendScript = (url, id, callback) => {
  if (document.getElementById(id) === null) {
    var e = document.createElement("script")
    e.src = url
    e.id = id
    e.async = true
    e.defer = true
    e.type = "text/javascript"
    e.addEventListener("load", callback)
    document.getElementsByTagName("body")[0].appendChild(e)
  }
}

export {
  SiteName,
  AltSiteName,
  NewsBaseURL,
  TeamBaseURL,
  AreaguideBaseURL,
  OfficeBaseURL,
  ListPropertyBaseURL,
  AppendScript,
}

export const numberFormat = num => {
  if (!num) return 0
  return new Intl.NumberFormat("en-US", {}).format(num)
}
export const numberFormatRound = (num) => {
  if (num == null) return 0;
  const roundedNum = Math.round(num);
  return new Intl.NumberFormat("en-US").format(roundedNum);
};

const baseURL = `${process.env.GATSBY_STRAPI_SRC}`
const token = process.env.GATSBY_STRAPI_FORM_TOKEN
// const token ="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzOTFjN2RhYjUxOWExMjI4YzMyMjU5NyIsImlhdCI6MTY3NTk0NTY4NCwiZXhwIjoxNjc4NTM3Njg0fQ.ntNtYIJDw6_F9ReWrPVrPbItUhLA71EQc1W6FDXdc9I";

export const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    Authorization: `Bearer ${token}`,
  },
})

export const isDateInArray = (array, value) => {
  return !!array.find(item => {
    return (
      item.getFullYear() === value.getFullYear() &&
      item.getMonth() === value.getMonth() &&
      item.getDate() === value.getDate()
    )
  })
}

export const getAvailableDates = availabilities => {
  const availableDates = []
  availabilities.forEach(availability => {
    const startDate = new Date(availability.StartDate)
    const endDate = new Date(availability.EndDate)

    while (startDate <= endDate) {
      availableDates.push(new Date(startDate))
      startDate.setDate(startDate.getDate() + 1)
    }
  })

  return availableDates
}

export const getTotalGuests = guests => {
  let totalGuests = 0
  if (guests.adults) totalGuests += guests.adults
  if (guests.children) totalGuests += guests.children
  return totalGuests
}

export const getTaxPrice = (price, taxPercent) => {
  price = parseInt(price)
  taxPercent = parseInt(taxPercent)

  return Math.floor(price * (taxPercent / 100))
}

export const getBookingPriceDetails = (data, bookingPriceData,{ startDate, endDate }) => {
  const displayData = []
  const  Currency = "AED"
  const RoomOnlyFinal = data.price
  const {touristTaxes }  = bookingPriceData
  // const prices = bookingPriceData.result.components.map(component => ({
  //   title: component.title,
  //   price: component.total
  // }));
  const start = new Date(startDate)
  const end = new Date(endDate)
  const nights = (end - start) / (1000 * 60 * 60 * 24)

  const nightText = nights > 1 ? "nights" : "night"
  const baseRate = bookingPriceData.result.components.find(component => component.name === 'baseRate');
  if (baseRate) {
    displayData.push([
      `Price for ${nights} ${nightText}`,
      `${numberFormatRound(baseRate.total)} ${Currency}`,
    ])
  }

  
const totalAmount =bookingPriceData.result.totalPrice
const components = bookingPriceData.result.components
.filter(component => component.name !== 'baseRate' && component.isIncludedInTotalPrice === 1); // Exclude baseRate and filter by isIncludedInTotalPrice

// Push each component's title and formatted total into displayData
components.forEach(component => {
  displayData.push([
    component.title,
    `${numberFormatRound(component.total)} ${Currency}`
  ]);
});  

// Add total tax and total amount to displayData
//displayData.push(['Total Tax Amount', `${numberFormat(totalTaxAmount)} ${Currency}`]);
displayData.push(['Total Amount', `${numberFormatRound(totalAmount)} ${Currency}`]);
  console.log(
    "🚀 ~ file: utils.js:94 ~ getBookingPriceDetails ~ data.BookingPrice",
    displayData
  )

  const totalPrice = totalAmount;
  // const totalPrice =
  //   parseInt(RoomOnlyFinal) + (isNaN(taxPrice) ? 0 : parseInt(taxPrice))
  console.log(
    "🚀 ~ file: utils.js:98 ~ getBookingPriceDetails ~ totalPrice",
    bookingPriceData
  )

  // calculate nights
 

  return { displayData, totalPrice, currency: Currency }
}

export const Capitalize = value => {
  if (typeof value !== "string") return ""
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const getClientSecret = async data => {
  const { amount, currency, description, name, address } = data
  try {
    const stripe = require("stripe")(process.env.GATSBY_STRIPE_SECRET_KEY)

    const paymentIntent = await stripe.paymentIntents.create({
      description: description,
      shipping: {
        name: name,
        address: address,
      },
      amount: parseInt(amount * 100),
      currency: currency,
      automatic_payment_methods: {
        enabled: true,
      },
    })

    return paymentIntent.client_secret
  } catch (error) {
    console.error("🚀 ~ file: utils.js:142 ~ getClientSecret ~ error", error)
  }
}

export const sendPaymentMail = async ({
  transactionID,
  reservationData,
  bookingCode,
  localizatorCode,
  propertyData,
  amount,
}) => {
  const {
    email,
    name,
    surname,
    telephone,
    adultsNumber,
    arrivalDate,
    departureDate,
  } = reservationData

  const formValues = {
    formname: "Payment Confirmation",
    email_subject_user: `Payment Confirmation`,
    email_subject_admin: `New Payment`,
    email_temp_user: "payment_confirmation_user",
    email_temp_admin: "payment_confirmation_admin",
    name: name,
    lastname: surname,
    email: email,
    telephone: telephone,
    adultsNumber,
    arrivalDate,
    departureDate,
    amount,
    transactionID,
    bookingCode,
    localizatorCode,
    propertyID: propertyData.crm_id,
    extra: JSON.stringify({
      adultsNumber,
      arrivalDate,
      departureDate,
      amount,
      transactionID,
      bookingCode,
      localizatorCode,
      propertyID: propertyData.crm_id,
    }),
  }

  const formData = new FormData()

  formData.append("data", JSON.stringify(formValues))

  postFormData(formData).then(async apiRes => {
    await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/form`, {
      method: `POST`,
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qs.stringify(formValues),
    })
  })
}
